import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "im-index"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            size: "large",
            type: "primary"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      borderd: false
    }
  }, [_c("a-radio-group", {
    on: {
      change: _vm.TabChange
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 待审核(" + _vm._s(_vm.penddingLen) + ") ")]), _c("a-radio-button", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 通过(" + _vm._s(_vm.resolveLen) + ") ")]), _c("a-radio-button", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 驳回(" + _vm._s(_vm.rejectLen) + ") ")])], 1), _c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    scopedSlots: _vm._u([{
      key: "reportnickname",
      fn: function fn(reportnickname, row) {
        return [_c("a", {
          attrs: {
            href: "/user/detail?uid=".concat(row.reportuid),
            target: "_blank"
          }
        }, [_vm._v(_vm._s(reportnickname))])];
      }
    }, {
      key: "bereportednickname",
      fn: function fn(bereportednickname, row) {
        return [_c("a", {
          attrs: {
            href: "/user/detail?uid=".concat(row.bereportedid),
            target: "_blank"
          }
        }, [_vm._v(_vm._s(bereportednickname))])];
      }
    }, {
      key: "bereportedtype",
      fn: function fn(bereportedtype, row) {
        return [_c("span", [_vm._v(_vm._s(bereportedtype ? _vm.itemReportType(bereportedtype) : ""))])];
      }
    }, {
      key: "createtime",
      fn: function fn(createtime, row) {
        return [_c("span", [_vm._v(_vm._s(_vm.$moment(Number(createtime)).utcOffset(8).format("YYYY-MM-DD HH:mm:ss")))])];
      }
    }, {
      key: "reportedstatus",
      fn: function fn(reportedstatus, row) {
        return [_c("span", [_vm._v(_vm._s(reportedstatus == 1 ? "待审核" : reportedstatus == 2 ? "审核通过" : "驳回"))])];
      }
    }, {
      key: "actions",
      fn: function fn(actions, row) {
        return [_c("div", [_c("a-col", [_c("a", {
          staticStyle: {
            color: "#666"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function click($event) {
              return _vm.handleButton("view", row);
            }
          }
        }, [_vm._v("查看")])]), _c("a-col", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.activeTab == 1,
            expression: "activeTab == 1"
          }],
          staticStyle: {
            "margin-top": "20px"
          }
        }, [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleButton("check", row);
            }
          }
        }, [_vm._v("审核")])], 1)], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange,
      showQuickJumper: false
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      width: "820px",
      title: "举报详情",
      footer: null,
      maskClosable: false,
      destroyOnClose: true
    },
    on: {
      cancel: function cancel($event) {
        _vm.visibleReport = false;
      }
    },
    model: {
      value: _vm.visibleReport,
      callback: function callback($$v) {
        _vm.visibleReport = $$v;
      },
      expression: "visibleReport"
    }
  }, [_c("detailVue", {
    attrs: {
      "is-view": _vm.optionIsView,
      "item-id": _vm.optionItem.id,
      "report-type": _vm.typeList
    },
    on: {
      close: _vm.closeModal
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };